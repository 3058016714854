import { useMutation } from 'react-query';

import { postUserPetVet } from './user-service';

export function usePostUserPetVet(options = {}) {
  return useMutation(
    ({ userId, petId, vetId }) => postUserPetVet(userId, petId, vetId),
    options
  );
}
