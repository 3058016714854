import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles } from '@pumpkincare/shared/ui';

import styles from './onboarding-welcome.css';

import welcome from './welcome.png';

function OnboardingWelcome({ onNextClick }) {
  return (
    <div className={styles.root}>
      <div>
        <div style={{ position: 'relative' }}>
          <div className={styles.background} />
          <img src={welcome} className={styles.image} alt='' />
        </div>

        <div>
          <h3 className={styles.header}>Give your pets' health the best start.</h3>
          <Body2 className={styles.copy}>
            Set up your account to ensure expedited care when you need it most.
          </Body2>
        </div>
      </div>

      <button
        className={classNames(ButtonStyles.primary, styles.button)}
        onClick={onNextClick}
      >
        I'm ready to begin
      </button>
    </div>
  );
}

OnboardingWelcome.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

export default OnboardingWelcome;
