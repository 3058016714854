import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles } from '@pumpkincare/shared/ui';
import {
  getUserRatingAddress,
  usePostUserPetVet,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';
import { VetClinic } from '@pumpkincare/vets/ui';

import styles from './onboarding-pet-vet-assignment.css';

function OnboardingPetVetAssignment({ onNextClick, pet }) {
  const { data: userData } = useUssr();
  const { data: addressData } = useUserAddresses();
  const rating = getUserRatingAddress(addressData);

  const { id: userId } = userData || {};
  const { id: petId, name: petName, vet: initialVet } = pet;

  const [vetState, setVetState] = useState({
    vetChanged: false,
    disabled: !initialVet,
    selectedVet: initialVet,
  });

  const { mutateAsync, isLoading } = usePostUserPetVet();

  function handleVetClinicChange(payload) {
    const isExistingVetChanged =
      payload.existing && payload.existing.id !== initialVet?.id;

    setVetState({
      vetChanged: isExistingVetChanged,
      disabled: !isExistingVetChanged,
      selectedVet: isExistingVetChanged ? payload.existing : null,
    });
  }

  function handleOnSave() {
    const { selectedVet, vetChanged } = vetState;
    if (vetChanged && userId && petId && selectedVet?.id) {
      mutateAsync({ userId, petId, vetId: selectedVet.id }).then(() =>
        onNextClick()
      );
    } else {
      onNextClick();
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <div className={styles.title}>
          <h3>Add a primary vet for {petName}.</h3>
          <Body2>This will be your regular or most current vet.</Body2>
        </div>
        <VetClinic
          onChange={handleVetClinicChange}
          selectedVet={vetState.selectedVet}
          label='Vet clinic'
          placeholder='Search for a Vet...'
          zipcode={rating?.zipcode}
        />
      </div>
      <div className={styles.buttons}>
        <button
          disabled={vetState.disabled || isLoading}
          onClick={handleOnSave}
          className={classNames(styles.button, ButtonStyles.primary)}
        >
          {isLoading ? 'Saving...' : 'Confirm & Save'}
        </button>
        <button
          disabled={!!initialVet}
          onClick={onNextClick}
          className={classNames(styles.button, ButtonStyles.tertiary)}
        >
          {petName} has not been to the vet
        </button>
      </div>
    </div>
  );
}

OnboardingPetVetAssignment.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  pet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vet: PropTypes.object,
  }),
};

OnboardingPetVetAssignment.defaultProps = {
  pet: {
    id: '',
    name: '',
    vet: null,
  },
};

export default OnboardingPetVetAssignment;
